<template>
  <div>
    <b-card no-body class="mb-0 alert-notification">
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="this.currentPerPage || 10"
          :columns="1"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        class="position-relative no-headers userlist-table child-1-30-percent alert-list-scroll"
        responsive
        show-empty
        v-if="!show"
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :tbody-tr-class="rowClass"
        :empty-text="$t('NoMatchingRecordsFound')"
        @row-clicked="handleRowClick"
        :hover="$route.name === 'notification-create'"
      >
        <!-- Column: User -->
        <template #cell(user)="row">
          <b-media class="align-item-center ml-10 pt-0">
            <template #aside>
              <b-avatar
                size="50"
                src="RA"
                :text="row.item.avtar_txt"
                :variant="row.item.variant"
              />
            </template>
            <h6 class="mb-0">{{ row.item.name }}</h6>
            <small class="text-muted">{{
              row.item.account && row.item.account.name
            }}</small>
          </b-media>
        </template>
        <template #cell(labels)="row">
          <div class="parent" id="parent">
            <Badge
              :tags="row.item.labels"
              :showTextInBadge="Number(7)"
              :event="row.item"
              :maxTagShow="Number(1)"
              v-if="$route.name === 'notification-create'"
            ></Badge>
            <DynamicBadge
              :isList="true"
              :entityType="'ALERT'"
              v-if="$route.name !== 'notification-create'"
              :tagsData="row.item.labels"
              :showTextInBadge="Number(7)"
              :event="row.item"
              :maxTagShow="Number(defaultBadgeShow)"
              :listApi="debounceGetAllAlert"
            ></DynamicBadge>
          </div>
        </template>
        <template #cell(status)="row">
          <b-badge
            pill
            :variant="row.item.status ? 'light-success' : 'light-danger'"
          >
            {{ row.item.status ? $t("active") : $t("inactive") }}
          </b-badge>
        </template>
        <template
          #cell(action)="row"
          v-if="$route.name !== 'notification-create'"
        >
          <div class="text-right list-icon mr-1">
            <span
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.ALERTS
                })
              "
              v-b-tooltip.hover.top.v-primary
              :title="$t('tooTip.clone')"
            >
              <b-link
                :to="{
                  name: 'alert-create',
                  query: { id: row.item.id, account_id: selectedAccount }
                }"
              >
                <feather-icon
                  icon="CopyIcon"
                  class="text-primary action-icon"
                  size="18"
                  style="position: relative; cursor: pointer"
                ></feather-icon>
              </b-link>
            </span>
            <span
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.READ,
                  subject: constants.PERMISSIONS_MODEL.ALERTS
                })
              "
              v-b-tooltip.hover.top.v-primary
              :title="$t('tooTip.view')"
            >
              <b-link
                :to="{
                  name: 'alert-view',
                  params: { id: row.item.id },
                  query: { account_id: selectedAccount }
                }"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="text-primary action-icon"
                  size="18"
                  style="position: relative; cursor: pointer"
                />
              </b-link>
            </span>

            <span
              v-if="
                row.item.is_editable == true &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.ALERTS
                }) &&
                !row.item.auto_created
              "
              v-b-tooltip.hover.top.v-primary
              :title="$t('tooTip.update')"
            >
              <b-link
                :to="{
                  name: 'alert-edit',
                  params: { id: row.item.id },
                  query: { account_id: selectedAccount }
                }"
              >
                <feather-icon
                  icon="EditIcon"
                  class="text-primary action-icon"
                  size="18"
                  style="position: relative; cursor: pointer"
                ></feather-icon>
              </b-link>
            </span>
            <span
              v-if="
                row.item.is_editable == true &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.DELETE,
                  subject: constants.PERMISSIONS_MODEL.ALERTS
                }) &&
                !row.item.auto_created
              "
              v-b-tooltip.hover.top.v-primary
              :title="$t('tooTip.delete')"
            >
              <feather-icon
                icon="Trash2Icon"
                @click="getAlertNotification(row.item)"
                class="text-danger action-icon"
                size="18"
                style="position: relative; cursor: pointer"
              />
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalAlert > 0"
              v-model="currentPage"
              :total-rows="totalAlert"
              :per-page="currentPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="handlePageChange"
              :no-paging-nav="false"
              :active-class="activeClass"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <RemoveAlerts
      :data="{
        ...removedAlertData,
        modelName: 'modal-sm-remove-alert-warring'
      }"
      :onClose="onClose"
      :removedUser="removedAlert"
    />
    <AreYouSureModal
      ref="areyousure"
      :data="{
        ...removedAlertData,
        modelName: 'modal-sm-remove-alert'
      }"
      :onClose="onClose"
      :removedUser="removedAlert"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";

import RemoveAlerts from "@/layouts/components/RemoveAlerts.vue";
import UnitService from "@/libs/api/unit-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import Badge from "@/layouts/components/Badge.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    BLink,
    BBadge,
    BPagination,
    RemoveAlerts,
    VBTooltip,
    BSkeletonTable,
    DynamicBadge,
    Badge,
    AreYouSureModal
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      tableColumns: [
        { key: "user", Class: "", label: this.$t("alert.tableColumns.user") },
        {
          key: "labels",
          tdClass: "group-width-right badge-230",
          thClass: "group-width-right badge-230",
          label: this.$t("alert.tableColumns.labels")
        },
        {
          key: "status",
          tdClass: "status-width",
          label: this.$t("alert.tableColumns.status")
        }
      ],
      totalAlert: 0,
      currentPerPage: this.nearestPage(
        ["10", "20", "30"],
        parseInt(window.innerHeight / 71)
      ),
      // perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      activeClass: "active",
      items: [],
      removedAlertData: {},
      show: false,
      notifications: [],
      totalNotifications: 0,
      defaultBadgeShow: 2,
      timeoutId: null
    };
  },
  props: [
    "searchFiled",
    "groupName",
    "perPage",
    "getSelectedAlert",
    "alertId",
    "isGroupEntity",
    "isAllowChild",
    "selectedAccount"
  ],

  watch: {
    selectedAccount(val) {
      this.currentPage = 1;
      this.debounceGetAllAlert();
    },
    perPage(val) {
      if (!this.perPage) {
        this.currentPerPage = 10;
      }
      this.currentPerPage = val;
      const query = Object.assign({}, this.$route.query);

      query.perPage = val;

      this.$router.replace({ query }).catch(() => {});

      this.debounceGetAllAlert();
    },
    currentPage() {},
    searchFiled(newVal, oldVal) {
      this.currentPage = 1;
      this.filter = newVal;
      this.debounceGetAllAlert();
    },
    isAllowChild() {
      this.debounceGetAllAlert();
    }
  },
  computed: {},
  methods: {
    debounceGetAllAlert() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllAlert();
      }, 300); // Adjust the delay as necessary
    },
    async getAllAlert() {
      try {
        this.show = true;
        const me = this;
        if (!this.filter || !this.filter.value) {
          if (this.$route.query.filter) {
            this.filter = {
              field: "name",
              operator: "ilike",
              value: this.$route.query.filter
            };
          } else {
            this.filter = {};
          }
        } else if (this.filter && this.filter.value) {
          if (this.$route.query.filter) {
            this.filter = {
              field: "name",
              operator: "ilike",
              value: this.filter.value
            };
          } else {
            this.filter = {};
          }
        }
        const query = Object.assign({}, this.$route.query);
        let requestData = {
          page: query.page ? Number(query.page) : this.currentPage,
          page_size: query.pageSize
            ? Number(query.pageSize)
            : parseInt(this.currentPerPage),
          is_allow_child: this.isAllowChild,
          account_id:
            this.selectedAccount || localStorage.getItem("USER_ACCOUNT_ID"),
          filters:
            this.filter && Object.keys(this.filter).length > 0
              ? [this.filter]
              : []
        };
        if (this.groupName) {
          requestData.filters = [
            {
              field: "labels",
              operator: "ilike",
              value: this.groupName
            }
          ];
        }

        let response = await new UnitService().getAlerts(requestData);

        if (response && response.data) {
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            u.variant = this.getRandomBgColor();
            u.del_tool = `del${u.id}`;
            u.up_tool = `up${u.id}`;
            u.variant = this.getRandomBgColor();
            u.avtar_txt = this.userAvatarTxt(u.name);
            return u;
          });
          this.totalAlert =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
      this.showLabelSize();
    },
    showLabelSize() {
      const parentElement = document.querySelector(".parent");
      if (parentElement) {
        const parentWidth = parentElement.offsetWidth;
        this.defaultBadgeShow = Math.floor(parentWidth / 71);
      }
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove-alert-warring");
      this.$bvModal.hide("modal-sm-remove-alert");
      this.debounceGetAllAlert();
    },
    close(data) {},
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.id === this.alertId) return "table-primary-light";
    },
    handleRowClick(e) {
      if (this.$route.name === "notification-create") {
        this.getSelectedAlert(e);
      }
    },
    async removedAlert(params) {
      try {
        this.show = true;
        if (!params || !params.id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return false;
        }
        this.$refs.areyousure.disabled = true;
        let response = await new UnitService().removeAlert({
          id: params.id,
          account_id: this.$route.query.account_id
        });
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: this.$t("alert.RemoveSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.onClose();
          this.$refs.areyousure.disabled = false;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$refs.areyousure.disabled = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$refs.areyousure.disabled = false;
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.debounceGetAllAlert();
    },
    async getAlertNotification(data) {
      try {
        this.show = true;

        let response = await new UnitService().getAlertUsedEntities({
          id: data.id,
          page: 1,
          page_size: 4
        });

        if (response && response.data) {
          this.show = false;
          const notifications = response.data.notifications.list || [];

          const totalNotifications =
            (response.data.notifications.pagination &&
              response.data.notifications.pagination.total_records) ||
            0;
          this.removedAlertData = {
            showData:
              notifications && notifications.length
                ? notifications.slice(0, 2)
                : [],
            showDataCount: totalNotifications > 2 ? totalNotifications - 2 : 0,
            ...data
          };
          if (notifications && notifications.length) {
            this.$bvModal.show("modal-sm-remove-alert-warring");
          } else {
            this.$bvModal.show("modal-sm-remove-alert");
          }
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }
      this.debounceGetAllAlert();
    });
    if (this.$route.name !== "notification-create") {
      this.tableColumns.push({ key: "action", tdClass: "alert-action-width" });
    } else {
      this.debounceGetAllAlert();
    }
    if (this.isGroupEntity) {
      this.debounceGetAllAlert();
    }
    window.addEventListener("resize", this.showLabelSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.showLabelSize);
  }
};
</script>
<style lang="scss">
.alert-action-width {
  max-width: 130px;
  width: 130px;
  min-width: 130px;
}
.status-width {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
  text-align: center;
}
.group-width-right {
  max-width: 200px;
  width: 200px;
  min-width: 200px;
  text-align: right !important;
}
.group-width {
  text-align: right;
}
.badge-group {
  height: 15px;
  padding: 1px 9px 1px 9px;
  font-size: 9px;
  line-height: 11px;
}
.ml-10 {
  margin-left: 10px;
}
</style>
<style lang="scss" scoped>
.alert-list-scroll {
  max-height: calc(100vh - 250px) !important;
  min-height: calc(100vh - 250px) !important;
  overflow-y: auto;
}
.alert-notification {
  .table-responsive {
    max-height: calc(100vh - 260px) !important;
    min-height: calc(100vh - 260px) !important;
    overflow-y: auto;
  }
}
</style>
